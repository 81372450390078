.App {
  overflow-x: hidden;
  min-height: 100vh;
  min-width: 100vw;
  background-color: black;
  font-family: 'Ubuntu', sans-serif;
}

.section {
  min-height: 100vh;
  min-width: 100vw;
  padding-top: 5%;
}